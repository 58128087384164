import { Component, OnInit } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { Subject } from 'rxjs';

import { LoaderService } from './shared/services/loader.service';
@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {
  title = 'bfl-adm-frontend';
  isLoading: Subject<boolean> = this.loaderService.isLoading;

  constructor(
    public http: HttpClient,
    private loaderService: LoaderService,
  ) { }

  ngOnInit(): void { }
}

import { SafeUrl } from '@angular/platform-browser';

const URL = 'https://workout.belfortlifestyle.com/';
const localhost = 'http://localhost:';

// EXTERNAL CONNECTION API
export const SERVER_API_URL = URL;
export const MS_VIDEO = URL;

// LOCAL CONNECTION API
// export const SERVER_API_URL = `${localhost}8080/`;
// export const MS_VIDEO = `${localhost}5000/`;


export const LANG_KEY = 'langKey';
export const FILE_TO_UPLOAD: string | SafeUrl = 'https://cdn.iconscout.com/icon/free/png-512/user-avatar-contact-portfolio-personal-portrait-profile-1-5182.png';
export const DEFAULT_VIDEO: string | SafeUrl = 'https://www.youtube.com/embed/YE7VzlLtp-4';

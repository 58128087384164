import { Injectable, isDevMode } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, CanLoad, Route, Router, RouterStateSnapshot, UrlSegment, UrlTree } from '@angular/router';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { Authority } from 'src/app/shared/constants/authority.constants';
import { AccountService } from './account.service';

import { StateStorageService } from './state-storage.service';

@Injectable({ providedIn: 'root' })
export class UserRouteAccessService implements CanActivate, CanLoad {
  

  constructor(
    private router: Router,
    private accountService: AccountService,
    private stateStorageService: StateStorageService
  ) { }

  canLoad(route: Route, segments: UrlSegment[]): boolean | UrlTree | Observable<boolean | UrlTree> | Promise<boolean | UrlTree> {
    
    if (!this.accountService.identity()) {
      this.router.navigate(['/login']);
      return false;
    }
    
    const roles = route.data && route.data.roles as Authority[];
    const hasAuthority = this.accountService.hasAnyAuthority(roles);
    
    if (roles.length > 0 && !hasAuthority) {
      this.router.navigate(['/login']);
      return false;
    }
    return true;
  }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> {
    const authorities = route.data && route.data.roles as Authority[];
    // We need to call the checkLogin / and so the accountService.identity() function, to ensure,
    // that the client has a principal too, if they already logged in by the server.
    // This could happen on a page refresh.
    return this.checkLogin(authorities, state.url);
  }

  checkLogin(authorities: string[], url: string): Observable<boolean> {
    
    return this.accountService.identity().pipe(
      map(account => {
        
        if (!authorities || authorities.length === 0) {
          return true;
        }

        if (account) {
          const hasAnyAuthority = this.accountService.hasAnyAuthority(authorities);
          if (hasAnyAuthority) {
            return true;
          }
          this.router.navigate(['/login']);
          return false;
        }

        this.stateStorageService.storeUrl(url);
        this.router.navigate(['/login']);
        return false;
      })
    );
  }
}

export enum Authority {
  ROLE_ADMIN = 'ROLE_ADMIN',
  ROLE_USER = 'ROLE_USER',
  ROLE_ADM_BELFORT = 'ROLE_ADM_BELFORT',
  ROLE_ADM_REGIONAL = 'ROLE_ADM_REGIONAL',
  ROLE_COACH = 'ROLE_COACH',
  ROLE_FRANQUEADO = 'ROLE_FRANQUEADO',
  ROLE_LOBBY = 'ROLE_LOBBY',
}

export const USER_AUTHORITIES = [
  { name : "General", role: Authority.ROLE_ADMIN},
  { name : "Admin", role: Authority.ROLE_ADM_BELFORT},
  { name : "Regional Admin", role: Authority.ROLE_ADM_REGIONAL},
  { name : "Coach", role: Authority.ROLE_COACH},
  { name : "Franchisee", role: Authority.ROLE_FRANQUEADO},
  { name : "Lobby", role: Authority.ROLE_LOBBY},
]
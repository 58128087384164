import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { UserRouteAccessService } from './core/auth/user-route-access-service';

import { Authority } from './shared/constants/authority.constants';

const routes: Routes = [
  { path: 'login', loadChildren: () => import('./login/login.module').then(m => m.LoginModule) },
  { path: 'forgot-password', loadChildren: () => import('./login/forgot-password/forgot-password.module').then(m => m.ForgotPasswordModule) },
  { path: 'finalize-forgot-password', loadChildren: () => import('./login/finalize-forgot-password/finalize-forgot-password.module').then(m => m.FinalizeForgotPasswordModule) },
  {
    path: '',
    canActivate: [UserRouteAccessService],
    canLoad: [UserRouteAccessService],
    data: { roles: Object.keys(Authority) },
    loadChildren: () => import('./dashboard/dashboard.module').then(m => m.DashboardModule)
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  providers: [UserRouteAccessService],
  exports: [RouterModule]
})

export class AppRoutingModule { }
